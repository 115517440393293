import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Signin from "../components/SignIn";
import Chat from "../components/Chat";

const Routes = () => (
	<Router>
		<Switch>
			<PrivateRoute exact path="/" component={Chat} />
			<Route path="/signin" component={Signin} />
		</Switch>
	</Router>
);

export default Routes;
