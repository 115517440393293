import React, { useState, useEffect, useCallback } from "react";
import { Spinner, Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { useAuth } from "../contexts/AuthContext";
import "./Chat.css";

import ChatListItem from "./ChatListItem";
import ChatWindow from "./ChatWindow";
import ChatIntro from "./ChatIntro";
import usePushNotification from "../hooks/PushNotification";
import RoomService from "../modules/chat/room/RoomService";

const roomService = new RoomService();

const Chat = () => {
	const { auth, currentUser, logout } = useAuth();

	const [loading, setLoading] = useState(true);
	const [chatList, setChatList] = useState([]);
	const [activeChat, setActiveChat] = useState({});

	const [search, setSearch] = useState("");

	usePushNotification();

	const updateRooms = useCallback(() => {
		if (!auth?.user?.firestore_id) return () => {};
		const unsub = roomService.onUserRoom(auth.user.firestore_id, setChatList, setLoading);
		if (unsub) {
			return () => unsub();
		}
		return () => {};
	}, [auth?.user?.firestore_id]);

	useEffect(() => {
		const unsub = updateRooms();
		return () => unsub();
	}, [updateRooms]);

	useEffect(() => {
		const listRooms = document.querySelectorAll(".chatListItem");
		listRooms.forEach((room) => {
			if (
				room.children[1].children[0].children[0].innerHTML
					.toLowerCase()
					.startsWith(search.toLowerCase())
			) {
				room.style.display = "flex";
			} else {
				room.style.display = "none";
			}
		});
	}, [search]);

	const handleLogout = async () => {
		try {
			await logout();
		} catch (e) {
			toast.error(e.message);
		}
	};

	return (
		<>
			<ToastContainer />
			<div hidden={!loading}>
				<Container
					className="d-flex align-items-center justify-content-center"
					style={{ minHeight: "100vh" }}
				>
					<Spinner animation="border" role="status" style={{ color: "#e21251" }}>
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</Container>
			</div>
			<div hidden={loading} className="app-window">
				<div className="sidebar">
					<header>
						<img className="header-avatar" src={currentUser.photoURL} alt="avatar" />
						<div className="header-buttons">
							<div
								className="header-btn"
								onClick={() => handleLogout()}
								role="button"
								tabIndex={0}
							>
								<ExitToAppRoundedIcon style={{ color: "#e21251" }} />
							</div>
						</div>
					</header>
					<div className="search">
						<div className="search-input">
							<SearchRoundedIcon fontSize="small" style={{ color: "#e21251" }} />
							<input
								className="search"
								type="search"
								placeholder="Procurar uma conversa"
								onChange={(e) => setSearch(e.target.value)}
							/>
						</div>
					</div>
					<div className="chatlist">
						{chatList.map((chat) => (
							<ChatListItem
								key={chat.chatId}
								active={activeChat === chat}
								data={chat}
								onClick={() => setActiveChat(chat)}
								callback={updateRooms}
							/>
						))}
					</div>
				</div>
				<div className="contentarea">
					{activeChat.chatId !== undefined && <ChatWindow chat={activeChat} />}
					{activeChat.chatId === undefined && <ChatIntro />}
				</div>
			</div>
		</>
	);
};

export default Chat;
