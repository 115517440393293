import BaseService from "../../common/database/firestore-base/BaseService";
import Message from "./entities/Message";

export default class MessageService extends BaseService {
	constructor(roomId) {
		super(`rooms/${roomId}/messages`, Message);
		this._roomId = roomId;
	}

	get roomId() {
		return this._roomId;
	}

	async sendMessage(id, message) {
		message = message?.trim().replace(/\n*$/, "") || "";
		if (message.length === 0) return;

		await this.add({
			user: id,
			text: message,
		});
	}

	async getLastMassage() {
		return this.getCollectionRef()
			.orderBy("createdAt", "desc")
			.limit(1)
			.get()
			.then((messages) => (messages.empty ? undefined : messages.docs[0].data()));
	}

	onMessageCount(callback) {
		return this.getCollectionRef().onSnapshot((query) =>
			callback(query.docs.length)
		);
	}

	onMessage(setMessages, limit, scrollTo) {
		return this.getCollectionRef()
			.orderBy("createdAt", "desc")
			.limit(limit)
			.onSnapshot((query) => {
				const messages = query.docs
					.map((item) => item.data())
					.map((message) => ({
						id: message.id,
						text: message.text,
						user: message.user,
						createdAt: message.createdAt,
					}))
					.reverse();
				setMessages(messages);
				scrollTo(messages.length !== 0 ? messages[0].id : "");
			});
	}

	onLastMessage(setLastMessage, callback) {
		return this.getCollectionRef()
			.orderBy("createdAt", "desc")
			.limit(1)
			.onSnapshot((query) => {
				const message = query.docs.map((item) => item.data())[0];
				if (message) {
					setLastMessage({
						message: message.text,
						createdAt: message.createdAt,
					});
				}
				callback();
			});
	}
}
