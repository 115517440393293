import React, {
	useContext,
	useState,
	useMemo,
	useCallback,
	useEffect,
} from "react";
import { getAuth, getProvider } from "../utils/firebase";
import { UserNotFoundError } from "../errors";
import { initAPIS } from "../api";
import UserService from "../modules/security/user/UserService";
import AuthService from "../modules/security/auth/AuthService";

const userService = new UserService();
const authService = new AuthService();
const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState();
	const [currentUser, setCurrentUser] = useState();

	const setAuthUser = useCallback(async (user) => {
		setCurrentUser(user);
		// Busca o usuário no firestore
		if (user) {
			const { id, isMaster, accesstoken, permissions } =
				await authService.loginToken(user.uid);
			const userLocal = await userService.fetchById(id, accesstoken);
			const authLocal = {
				vividusJwt: accesstoken,
				isMaster,
				user: {
					id: userLocal.id,
					name: userLocal.name,
					cep: userLocal.cep,
					avatar: userLocal.avatar,
					auth_id: userLocal.auth_id,
					firestore_id: userLocal.firestore_id || userLocal.id,
				},
				permissions,
			};
			setAuth(authLocal);
			initAPIS(authLocal);
			return true;
		}
		setAuth(undefined);
		setAuth(undefined);
		return false;
	}, []);

	useEffect(() => {
		const unsubscribe = getAuth().onAuthStateChanged((user) => {
			setAuthUser(user);
		});
		return () => unsubscribe();
	}, [setAuthUser]);

	const signinWithGoogle = useCallback(
		() =>
			getAuth()
				.signInWithPopup(getProvider())
				.then(async (credentials) => {
					if (!credentials.user.email) throw new UserNotFoundError();
					const { exists } = await authService.getVerifyByEmail(
						credentials.user.email
					);
					if (!exists) {
						await logout();
						throw new UserNotFoundError();
					}
				}),
		[]
	);
	const logout = () =>
		getAuth()
			.signOut()
			.then(() => {
				setCurrentUser(undefined);
				setAuth(undefined);
			});

	const value = useMemo(
		() => ({
			auth,
			currentUser,
			signinWithGoogle,
			logout,
		}),
		[auth, currentUser, signinWithGoogle]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
	return useContext(AuthContext);
}
