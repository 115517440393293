import firebase from "firebase/app";
import "firebase/firestore";

export default class BaseService {
	/**
	 * Retorna uma instância do banco Firestore.
	 *
	 * @returns Instância do Firestore
	 */
	static db() {
		return firebase.firestore();
	}

	/**
	 * Construtor do serviço.
	 *
	 * @param collection Path ou nome da coleção no Firestore.
	 * @example "users"
	 * @example `rooms/${roomId}/messages`
	 */
	constructor(collection, type) {
		this._type = type;
		this._collection = collection;
	}

	get collection() {
		return this._collection;
	}

	/**
	 * Converte o modelo de objeto para o modelo de dados do Firestore.
	 * {@link https://firebase.google.com/docs/reference/js/v8/firebase.firestore.FirestoreDataConverter}
	 *
	 * @param modelObject Modelo do objeto
	 * @returns Dados a serem persistidos no Firestore
	 */
	toFirestore(modelObject) {
		const data = { ...modelObject };
		Reflect.deleteProperty(data, "id");
		return data;
	}

	/**
	 * Converte o modelo de dados do Firestore para o modelo de dados utilizado no app.
	 * {@link https://firebase.google.com/docs/reference/js/v8/firebase.firestore.FirestoreDataConverter}
	 *
	 * @param snapshot Snapshot do modelo de dados do Firestore
	 * @param options Opções de conversão do snapshot
	 * @returns Modelo de dados utilizado no app, com o id do registro.
	 */
	fromFirestore(snapshot, options) {
		const model = snapshot.data(options);
		model.id = snapshot.id;
		return new this._type(model);
	}

	/**
	 * Retorna a coleção padrão do modelo de dados, com seu conversor.
	 *
	 * @returns Coleção de documentos do Firestore com seu conversor.
	 */
	getCollectionRef() {
		return BaseService.db().collection(this.collection).withConverter(this);
	}

	/**
	 * Busca um objeto pelo seu ID.
	 *
	 * @param id id do registro no firestore
	 * @returns o objeto buscado ou undefined
	 */
	async getById(id) {
		return this.getCollectionRef()
			.doc(id)
			.get()
			.then((doc) => doc.data());
	}

	/**
	 * Usa o id fornecido para buscar um objeto e chamar o callback sempre que o objeto for modificado no banco
	 *
	 * @param id id do registro no firestore
	 * @param callback metodo que recebe o objeto modificado
	 * @returns o objeto buscado ou undefined
	 */
	subscribeToDoc(id, callback) {
		return this.getCollectionRef()
			.doc(id)
			.onSnapshot(async (doc) => {
				callback(doc.data());
			});
	}

	/**
	 * Cria um novo registro do objeto no Firestore. Adiciona automaticamente o campo de data de criação.
	 *
	 * @param data Objeto a ser adicionado
	 * @returns o ID do objeto criado
	 */
	async add(data) {
		Reflect.deleteProperty(data, "createdAt");
		data = this.prepareToAdd(data);
		return this.getCollectionRef()
			.add({
				...data,
				createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			})
			.then((doc) => doc.id);
	}

	/**
	 * Altera o documento no banco da seguinte forma. Dados existentes no objeto passado (data) são criados no banco caso não existam,
	 * e dados que já existam no banco são reescritos pelos dados passados em data, qualquer outro
	 * dado não passado mas que exista no banco permanece inalterado
	 *
	 * @param data Objeto com os campos a serem alterados
	 * @returns void ou erro
	 */
	async update(data) {
		return this.getCollectionRef().doc(data.id).set(data, { merge: true });
	}

	prepareToAdd(data) {
		return data;
	}
}
