import React, { useState, useEffect, useRef } from "react";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import MessageItem from "./MessageItem";
import "./ChatWindow.css";
import { useAuth } from "../contexts/AuthContext";
import MessageService from "../modules/chat/message/MessageService";
import { DEFAULT_AVATAR } from "../utils/string";

const increment = 20;

const ChatWindow = ({ chat }) => {
	const { auth } = useAuth();
	const [messagesList, setMessagesList] = useState([]);
	const [limit, setLimit] = useState(increment);
	const [totalMessagesCount, setTotalMessagesCount] = useState(0);
	const [textAreaRows, setTextAreaRows] = useState(1);
	const [text, setText] = useState("");
	const [lastMessageId, setLastMessageId] = useState("");
	const scrollBottomRef = useRef();
	const scrollLastMessage = useRef();

	useEffect(() => {
		const count = text.split(/\r\n|\r|\n/).length;
		setTextAreaRows(count > 5 ? 5 : count);
	}, [text]);

	useEffect(() => {
		if (chat?.chatId) {
			setLastMessageId("");
			setTotalMessagesCount(0);
			setLimit(increment);
		}
	}, [chat?.chatId]);

	useEffect(() => {
		if (!chat?.chatId) return () => {};
		const unsub = new MessageService(chat.chatId).onMessageCount(
			setTotalMessagesCount
		);
		return () => unsub();
	}, [chat?.chatId]);

	useEffect(() => {
		if (!chat?.chatId) return () => {};
		const unsub = new MessageService(chat.chatId).onMessage(
			setMessagesList,
			limit,
			limit !== increment ? scrollToLastMessage : scrollToBottom
		);
		return () => unsub();
	}, [chat?.chatId, limit]);

	const handleSendMessage = () => {
		const message = text.trim().replace(/\n*$/, "") || "";
		if (message.length !== 0 && auth?.user?.firestore_id) {
			new MessageService(chat.chatId).sendMessage(auth.user.firestore_id, message);
			setText("");
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter" && !event.shiftKey) {
			handleSendMessage();
			event.preventDefault();
		}
	};

	const handleLoadMore = () => {
		setLimit(limit + increment);
	};

	const scrollToLastMessage = (newLastMessageId) => {
		if (scrollLastMessage.current) {
			scrollLastMessage.current.scrollIntoView();
			if (newLastMessageId) setLastMessageId(newLastMessageId);
		}
	};

	const scrollToBottom = (newLastMessageId) => {
		if (scrollBottomRef.current) {
			scrollBottomRef.current.scrollIntoView();
			if (newLastMessageId) setLastMessageId(newLastMessageId);
		}
	};

	return (
		<div className="chatWindow">
			<div className="chatWindow-header">
				<div className="chatWindow-headerinfo">
					<img
						className="chatWindow-avatar"
						src={chat?.avatar || DEFAULT_AVATAR}
						alt="avatar"
					/>
					<div className="chatWindow-name">{chat?.title || chat.userId || ""}</div>
				</div>
			</div>
			<div className="chatWindow-body">
				{totalMessagesCount > limit && (
					<div
						className="chatWindow-refresh"
						onClick={() => handleLoadMore()}
						role="button"
						tabIndex={0}
					>
						Carregar Mais
					</div>
				)}
				{messagesList.map((message) => (
					<div
						key={message.id}
						ref={message.id === lastMessageId ? scrollLastMessage : undefined}
					>
						<MessageItem data={message} key={message.id} />
					</div>
				))}
				<div ref={scrollBottomRef} />
			</div>
			<div className="chatWindow-footer">
				<div className="chatWindow-inputarea">
					<textarea
						id="chat-input"
						value={text}
						onChange={(e) => setText(e.target.value)}
						onKeyPress={handleKeyPress.bind(this)}
						className="chatWindow-input"
						type="text"
						rows={textAreaRows}
						placeholder="Digite uma mensagem"
					/>
				</div>
				<div className="chatWindow-pos">
					<div className="chatWindow-btn">
						<SendRoundedIcon
							style={{ color: "#e21251" }}
							onClick={() => handleSendMessage()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatWindow;
