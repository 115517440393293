import React, { useState } from "react";
import { Card, Button, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";
import logo from "../assets/adaptive-icon.png";
import googlelogo from "../assets/google-logo.png";

const Signin = () => {
	const { currentUser, signinWithGoogle } = useAuth();
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	if (currentUser) {
		history.push("/");
	}

	const signIn = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			await signinWithGoogle();
			history.replace("/");
		} catch (e) {
			let message = "";
			switch (e.code) {
				case "auth/popup-closed-by-user":
					message = "Popup foi fechado antes da operação ser finalizada!";
					break;
				default:
					message = e.message;
					break;
			}
			setLoading(false);
			toast.error(message);
		}
	};

	return (
		<Container
			className="d-flex align-items-center justify-content-center"
			style={{ minHeight: "100vh" }}
		>
			<ToastContainer />
			<div className="w-100" style={{ maxWidth: "400px" }}>
				<Card className="login-card">
					<Card.Body>
						<Container className="d-flex align-items-center justify-content-center">
							<img
								src={logo}
								alt="logo"
								width="100px"
								style={{ alignSelf: "center" }}
							/>
						</Container>
						<Container className="mt-4">
							<Button
								disabled={loading}
								className="w-100 d-flex align-items-center justify-content-center"
								onClick={(e) => signIn(e)}
							>
								<img
									src={googlelogo}
									alt="google"
									width="30px"
									style={{ marginRight: "10px" }}
								/>
								Entrar com Google
							</Button>
						</Container>
					</Card.Body>
				</Card>
			</div>
		</Container>
	);
};

export default Signin;
