import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

export const fireBaseConfig = () => ({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
});

let googleProvider;

export const firebaseInit = () => {
	if (firebase.apps.length === 0) {
		firebase.initializeApp(fireBaseConfig());
		googleProvider = new firebase.auth.GoogleAuthProvider();
		googleProvider.setCustomParameters({
			prompt: "select_account",
		});
	}
};

export const getMessaging = () => firebase.messaging();
export const getAuth = () => firebase.auth();
export const getProvider = () => googleProvider;
