import { VividusAPI } from "../../../api/VividusAPI";
import BaseService from "../../common/database/firestore-base/BaseService";
import User from "./entities/User";

export default class UserService extends BaseService {
	constructor() {
		super("users", User);
		this.api = new VividusAPI();
	}

	saveFCMToken(idUser, token) {
		return this.getCollectionRef().doc(`${idUser}`).set(
			{
				tokenFCM: token,
			},
			{
				merge: true,
			}
		);
	}

	fetchById(id, accesstoken) {
		return this.api.getUser(id, { loadProvider: true }, accesstoken);
	}

	fetchByFirestoreId(firestore_id) {
		return this.api
			.getUserPaginated({ firestore_id })
			.then((r) => r.data)
			.then((data) => data[0]);
	}
}
