/* eslint-disable no-new */
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import UserService from "../modules/security/user/UserService";
import { getMessaging } from "../utils/firebase";

const userService = new UserService();

const usePushNotification = () => {
	const { currentUser, auth } = useAuth();
	const [isTokenFound, setTokenFound] = useState(false);

	useEffect(() => {
		if (!currentUser || !auth?.user?.firestore_id) return;
		const messaging = getMessaging();
		messaging
			.getToken({
				vapidKey: process.env.REACT_APP_FIREBASE_VAPID,
			})
			.then((currentToken) => {
				console.log(auth.user.firestore_id, currentToken);
				if (currentToken) {
					userService.saveFCMToken(auth.user.firestore_id, currentToken);
					setTokenFound(true);
				} else {
					setTokenFound(false);
				}
			})
			.catch(console.error);
	}, [currentUser, auth?.user?.firestore_id]);

	useEffect(() => {
		if (!isTokenFound) return;
		const messaging = getMessaging();
		messaging.onMessage((payload) => {
			new Notification(payload.notification.title, payload.notification);
		});
	}, [isTokenFound]);

	return isTokenFound;
};

export default usePushNotification;
