export class AxiosError extends Error {
	constructor(status, message, data) {
		super(message);
		this.status = status;
		this.data = data;
	}
}
export class VividusError extends AxiosError {
	constructor(status, code, message, data) {
		super(status, message, data);
		this.code = code;
	}
}

export class UserNotFoundError extends Error {
	constructor() {
		super("Usuário ou e-mail não encontrado.");
	}
}
