import BaseAPI from "./BaseAPI";

export class VividusAPI extends BaseAPI {
	constructor() {
		super("AXIOS_VIVIDUS", process.env.REACT_APP_VIVIDUS_BACKEND || "");
		this.api.defaults.headers.common["x-vividus-origin"] = "MESSAGE";
	}

	async postLoginToken(auth_id) {
		return this.api
			.post(
				"api/auth/login/token",
				{
					auth_id,
				},
				{
					headers: {
						authorization: VividusAPI.getBasicAuth(),
					},
				}
			)
			.then((response) => response.data);
	}

	getVerifyMember(params) {
		return this.api
			.get("api/auth/verify/member", {
				params,
				headers: {
					authorization: VividusAPI.getBasicAuth(),
				},
			})
			.then((response) => response.data);
	}

	getUser(id, params, accesstoken) {
		return this.api
			.get(`api/security/user/${id}`, {
				params,
				headers: {
					authorization: accesstoken ? `Bearer ${accesstoken}` : VividusAPI.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getUserPaginated(params) {
		return this.api
			.get("api/security/user", {
				params,
				headers: {
					authorization: VividusAPI.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}
}
