import firebase from "firebase";
import moment from "moment-timezone";
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import "./MessageItem.css";

const MessageItem = ({ data }) => {
	const { auth } = useAuth();

	const toStringTime = (date) => {
		if (!date) return "";
		const mDate = moment(date.toDate());
		const today = moment(firebase.firestore.Timestamp.now().toDate());
		if (mDate.isSame(today, "day")) {
			return mDate.format("HH:mm");
		}
		if (today.diff(mDate, "day") <= 7) {
			return mDate.format("ddd, HH:mm");
		}
		return mDate.format("LLL");
	};

	return (
		<div
			className="messageLine"
			style={{
				justifyContent: data.user === auth?.user?.firestore_id ? "flex-end" : "flex-start",
			}}
		>
			<div
				className="messageItem"
				style={{
					backgroundColor: data.user === auth?.user?.firestore_id ? "#e2125033" : "#FFF",
				}}
			>
				<p className="messageText">{data.text}</p>
				<div className="messageDate">{toStringTime(data.createdAt)}</div>
			</div>
		</div>
	);
};

export default MessageItem;
