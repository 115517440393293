import firebase from "firebase";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import "./ChatListItem.css";
import MessageService from "../modules/chat/message/MessageService";
import { DEFAULT_AVATAR } from "../utils/string";

const ChatListItem = ({ onClick, active, data, callback }) => {
	const [lastMessage, setLastMessage] = useState({});

	const toStringTime = (date) => {
		if (!date) return "";
		const mDate = moment(date.toDate());
		const today = moment(firebase.firestore.Timestamp.now().toDate());
		if (mDate.isSame(today, "day")) {
			return mDate.format("HH:mm");
		}
		if (today.diff(mDate, "day") <= 7) {
			return mDate.format("ddd, HH:mm");
		}
		return mDate.format("L LT");
	};

	useEffect(() => {
		if (!data?.chatId) return () => {};
		const unsub = new MessageService(data.chatId).onLastMessage(
			setLastMessage,
			callback
		);
		return () => unsub();
	}, [data?.chatId, callback]);

	return (
		<div
			className={`chatListItem ${active ? "active" : ""}`}
			onClick={onClick}
			role="button"
			tabIndex={0}
		>
			<img
				className="chatListItem-avatar"
				src={data?.avatar || DEFAULT_AVATAR}
				alt="avatar"
			/>
			<div className="chatListItem-lines">
				<div className="chatListItem-line">
					<div className="chatListItem-name">{data?.title || "Unknown"}</div>
					<div className="chatListItem-date">
						{toStringTime(lastMessage?.createdAt)}
					</div>
				</div>
				<div className="chatListItem-line">
					<div className="chatListItem-lastMsg">
						<p>{lastMessage?.message || ""}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatListItem;
