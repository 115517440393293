import moment from "moment-timezone";
import "moment/locale/pt-br";

import { AuthProvider } from "./contexts/AuthContext";
import { firebaseInit } from "./utils/firebase";
import Routes from "./routes";

import "react-toastify/dist/ReactToastify.css";

firebaseInit();

moment.locale("pt-br");
moment.tz.setDefault("America/Sao_Paulo");

const App = () => (
	<AuthProvider>
		<Routes />
	</AuthProvider>
);

export default App;
