import React from "react";
import "./ChatIntro.css";
import logo from "../assets/adaptive-icon.png";

const ChatIntro = () => (
	<div className="chatIntro">
		<img src={logo} alt="logo" width="100px" style={{ alignSelf: "center" }} />
		<h1>Bem-vindo</h1>
	</div>
);

export default ChatIntro;
