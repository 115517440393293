import BaseModel from "../../../common/database/firestore-base/entities/BaseModel";

export default class User extends BaseModel {
	constructor(options) {
		super(options);
		typeof options.name !== "undefined" && (this.name = options.name);
		typeof options.tokenFCM !== "undefined" && (this.tokenFCM = options.tokenFCM);
		typeof options.auth_id !== "undefined" && (this.auth_id = options.auth_id);
		typeof options.email !== "undefined" && (this.email = options.email);
		typeof options.cellphone !== "undefined" &&
			(this.cellphone = options.cellphone);
		typeof options.avatar !== "undefined" && (this.avatar = options.avatar);

		typeof options.plan_id !== "undefined" && (this.plan_id = options.plan_id);
		typeof options.id_iugu !== "undefined" && (this.id_iugu = options.id_iugu);
		typeof options.id_iugu_subscription !== "undefined" &&
			(this.id_iugu_subscription = options.id_iugu_subscription);
		typeof options.default_iugu_payment_method !== "undefined" &&
			(this.default_iugu_payment_method = options.default_iugu_payment_method);
		typeof options.iuguPaymentMethod !== "undefined" &&
			(this.iuguPaymentMethod = options.iuguPaymentMethod);
		typeof options.activeSubscription !== "undefined" &&
			(this.activeSubscription = options.activeSubscription);

		typeof options.cep !== "undefined" && (this.cep = options.cep);
		typeof options.cpf !== "undefined" && (this.cpf = options.cpf);
		typeof options.healthmapIdExterno !== "undefined" &&
			(this.healthmapIdExterno = options.healthmapIdExterno);
		typeof options.idHmap !== "undefined" && (this.idHmap = options.idHmap);

		typeof options.role !== "undefined" && (this.role = options.role);
		typeof options.active !== "undefined" && (this.active = options.active);
		typeof options.gender !== "undefined" && (this.gender = options.gender);
		typeof options.birthDate !== "undefined" &&
			(this.birthDate = options.birthDate); // firebase.firestore.Timestamp
		typeof options.balance !== "undefined" && (this.balance = options.balance);
	}
}
