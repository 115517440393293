import BaseService from "../../common/database/firestore-base/BaseService";
import UserService from "../../security/user/UserService";
import MessageService from "../message/MessageService";
import Room from "./entities/Room";

export default class RoomService extends BaseService {
	constructor() {
		super("rooms", Room);
		this.userService = new UserService();
	}

	onUserRoom(userId, setChatList, setLoading) {
		return this.getCollectionRef()
			.where(`users.${userId}`, "==", true)
			.onSnapshot(async (query) => {
				const rooms = query.docs.map((item) => item.data());

				const promises = rooms.map((room) => this.createChatItem(userId, room));
				const localChatList = (await Promise.all(promises)).sort((a, b) => {
					if (a.lastMessage == null && b.lastMessage == null) {
						return a.title > b.title ? 1 : -1;
					}
					if (a.lastMessage == null) {
						return 1;
					}
					if (b.lastMessage == null) {
						return -1;
					}
					if (a.lastMessage.createdAt > b.lastMessage.createdAt) {
						return -1;
					}
					return 1;
				});
				setChatList(localChatList);
				setLoading(false);
			});
	}

	async createChatItem(userId, room) {
		const userIdLocal = Object.keys(room.users).filter(
			(user) => user !== userId
		)[0];
		const user = await this.userService.fetchByFirestoreId(userIdLocal);
		const lastMessage = await new MessageService(room.id).getLastMassage();
		return {
			chatId: room.id,
			title: user?.name,
			avatar: user?.avatarUrl,
			userId: userIdLocal,
			lastMessage,
		};
	}

	async getChatList(userId) {
		return this.getCollectionRef()
			.where(`users.${userId}`, "==", true)
			.get()
			.then((query) => {
				const promises = query.docs
					.map((item) => item.data())
					.map((room) => this.createChatItem(userId, room));
				return Promise.all(promises).then((list) =>
					list.sort((a, b) => {
						if (a.lastMessage == null && b.lastMessage == null) {
							return a.title > b.title ? 1 : -1;
						}
						if (a.lastMessage == null) {
							return 1;
						}
						if (b.lastMessage == null) {
							return -1;
						}
						if (a.lastMessage > b.lastMessage) {
							return -1;
						}
						return 1;
					})
				);
			});
	}
}
