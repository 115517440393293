import { VividusAPI } from "./VividusAPI";

export const clearAPIS = () => {
	VividusAPI.clear();
};

export const initAPIS = (auth) => {
	if (!auth || !auth.vividusJwt) {
		clearAPIS();
		return;
	}
	VividusAPI.init(auth);
};
